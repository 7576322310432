import {
  Button,
  Container,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { FunctionComponent } from "react"
import MediaInfo from "../../components/mediaInfo"
import Navbar from "../../components/navbar"
import { getApiUrl } from "../../utils/functions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      maxWidth: 300,
      marginTop: theme.spacing(2),
    },
    imgLogo: {
      maxWidth: 150,
      marginTop: theme.spacing(3),
    },
    title: {
      color: theme.palette.primary.main,
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      color: "#C9AA7C",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    centrar: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    texto: {
      textAlign: "left",
    },
    footer: {
      textAlign: "center",
      backgroundColor: "#C9AA7C",
      position: "fixed",
      bottom: 0,
      width: "100%",
      padding: 5,
    },
    negritas: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  })
)

const PortalCreditos: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <>
      <MediaInfo />
      <Navbar />
      <Container style={{ position: "relative" }}>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <img
              src={require("../../assets/images/logo/logo-isset-negro.png")}
              className={classes.imgLogo}
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>
              Portal de Créditos
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.subtitle}>
              ¿Qué es el portal de créditos?
            </Typography>
            <Typography variant="body1">
              Aquí, podrás realizar las solicitudes de crédito
              personal.<br></br>
              Para tener acceso a estos servicos, es necesario que cuentes con
              tu{" "}
              <span className={classes.negritas}>
                número de cuenta ISSET, RFC y contraseña
              </span>
              . Si no tienes una cuenta de acceso, podrás generar una nueva <Link className={classes.negritas} href="http://creditos.isset.gob.mx:8080/ords/modulos/r/admon_app_portal/login-page">aqui</Link>.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className={classes.subtitle}>
              ¿Qué trabajadores tienen derecho?
            </Typography>
            <ul>
              <li>Base.</li>
              <li>Confianza.</li>
              <li>Magisterio docente.</li>
              <li>Pensionado.</li>
              <li>Jubilado.</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" className={classes.subtitle}>
              Requisitos para solicitar un Préstamo a Corto Plazo
            </Typography>
            <Typography variant="body1">
              Para llevar a cabo tu solicitud de manera exitosa, se te
              solicitarán los siguientes documentos que deberás de enviarnos a través de la plataforma{" "}
              <span className={classes.negritas}>
                (escaneado y en formato PDF)
              </span>
              :
            </Typography>
            <ul>
              <li>Tener como mínimo un año de contribuciones al Fondo del ISSET.</li>
              <li>Último recibo de pago de nomina.</li>
              <li>Identificación oficial vigente con fotografía (INE O Pasaporte).</li>
              <li>Comprobante de domicilio no mayor a 2 meses (recibo de luz, agua o teléfono).</li>
              <li>Estado de cuenta del asegurado, que incluya el banco receptor, número de cuenta clabe (clabe interbancaria) debe ser original con fecha de expedición no mayor a 3 meses</li>
              <li>Clave única de registro de Población (CURP).</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" className={classes.subtitle}>
              Fundamento Jurídico
            </Typography>
            <ul>
              <li>1.- Ley de Seguridad Social del Estado de Tabasco artículos 5, 6, 7 parte "in fine" 8, 48, 49 y 50 Fracción IV, inciso a).</li>
              <li>2.- Reglamento de la Ley de Seguridad Social del Estado de Tabasco, articulo 123 y 124.</li>
              <li>3.- Reglamento de Otorgamiento del Procedimiento Administrativo para el Otorgamiento y Recuperación de Préstamos Personales a Corto Plazo.</li>
            </ul>
          </Grid>
          <Grid item container xs={12} md={4} alignContent='center'  >
            <Grid item xs={12}>
              <Button
                className={classes.button}
                variant="contained"
                href={
                  "https://creditos.isset.gob.mx:8443/ords/modulos/r/admon_app_portal/login-page"
                }
              >
                ACCEDER
              </Button>
            </Grid>

          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <Alert severity="info">Si aún no estás familiarizado con el nuevo sistema, puedes revisar el <Link target="_blank" className={classes.negritas} href={`${getApiUrl("portal")}/static/docs/manuales/GuiadeUsuarioPortaldeCreditos.pdf`}>manual de usuario</Link></Alert>
          </Grid>
          <Grid item xs={12} md={12} className={classes.centrar}>
            <img
              className={classes.img}
              src={require("../../assets/images/secundary-pages/credito.svg")}
            />
          </Grid>
        </Grid>
      </Container>
      <footer className={classes.footer}>
        <Typography variant="subtitle2">
          Gobiernos del estado de Tabasco | Todos los derechos reservados - 2022
        </Typography>
      </footer>
    </>
  )
}

export default PortalCreditos
